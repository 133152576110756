import moment from 'moment'
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useProductActivity() {
  const tableColumns = ref([
    {
      label: '',
      key: 'user_id',
      tdClass: 'minw-80',
    },
    {
      label: 'Thứ',
      key: 'fullname',
      tdClass: 'minw-190',
    },
    {
      label: 'T2',
      key: 'total_access_time_minutes_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'total_access_time_minutes_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'total_access_time_minutes_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'total_access_time_minutes_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'total_access_time_minutes_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'total_access_time_minutes_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'total_access_time_minutes_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
    {
      label: 'T2',
      key: 'list_detail_count_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'list_detail_count_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'list_detail_count_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'list_detail_count_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'list_detail_count_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'list_detail_count_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'list_detail_count_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
    {
      label: 'T2',
      key: 'client_count_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'client_count_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'client_count_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'client_count_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'client_count_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'client_count_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'client_count_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
    {
      label: 'T2',
      key: 'login_count_computer_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'login_count_computer_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'login_count_computer_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'login_count_computer_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'login_count_computer_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'login_count_computer_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'login_count_computer_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
    {
      label: 'T2',
      key: 'login_count_phone_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'login_count_phone_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'login_count_phone_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'login_count_phone_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'login_count_phone_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'login_count_phone_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'login_count_phone_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
    {
      label: 'T2',
      key: 'list_count_T2',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T3',
      key: 'list_count_T3',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T4',
      key: 'list_count_T4',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T5',
      key: 'list_count_T5',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T6',
      key: 'list_count_T6',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'T7',
      key: 'list_count_T7',
      sortable: true,
      tdClass: 'minw-50',
    },
    {
      label: 'CN',
      key: 'list_count_CN',
      sortable: true,
      tdClass: 'minw-50 td-sunday',
    },
  ])
  const filterDateRange = ref(new Date())
  const weekDates = computed(() => {
    const startOfWeek = moment(filterDateRange.value).startOf('isoWeek')
    const endOfWeek = moment(filterDateRange.value).endOf('isoWeek')
    const dates = []

    const currentDate = startOfWeek.clone()
    while (currentDate.isSameOrBefore(endOfWeek)) {
      dates.push(moment(currentDate.toDate()).format('YYYY-MM-DD'))
      currentDate.add(1, 'days')
    }
    return dates
  })
  const reportList = ref([])
  const reportListWeek = computed(() => reportList.value.map(item => {
    const data = item.data || []
    const dataWeek = {}
    data.forEach(item => {
      if (weekDates.value.includes(item.date)) {
        dataWeek[`${'total_access_time_minutes_'}${item.weekday}`] = Math.ceil(item.total_access_time / 60)
        dataWeek[`${'list_detail_count_'}${item.weekday}`] = item.list_detail_count
        dataWeek[`${'client_count_'}${item.weekday}`] = item.client_count
        dataWeek[`${'login_count_computer_'}${item.weekday}`] = item.login_count_computer
        dataWeek[`${'login_count_phone_'}${item.weekday}`] = item.login_count_phone
        dataWeek[`${'list_count_'}${item.weekday}`] = item.list_count
      }
    })
    return {
      user_id: item.user_id,
      fullname: item.fullname,
      ...dataWeek,
    }
  }))

  const buildQueryWithCustom = customFilter => http.buildQuery({
    ...({
      from: http.getISODate(moment(filterDateRange.value).startOf('isoWeek').toDate().setHours(0, 0, 0, 0)),
      to: http.getISODate((moment(filterDateRange.value).endOf('isoWeek').add(1, 'days')).toDate()
        .setHours(23, 59, 59, 999)),
    }),
    ...customFilter,
  })

  const fetchReportOverview = customFilter => {
    const queryString = buildQueryWithCustom({
      ...customFilter,
    })
    return http.handle(store.dispatch('report/fetchReportOverview', {
      q: queryString,
    }), res => {
      reportList.value = (res.data || []).map(item => ({
        ...item,
        total_access_time_minutes: Math.ceil(item.total_access_time / 60),
      }))
    })
  }
  watch([filterDateRange], () => {
    fetchReportOverview()
  })
  return {
    tableColumns,
    fetchReportOverview,
    reportList,
    filterDateRange,
    weekDates,
    reportListWeek,
  }
}

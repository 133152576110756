<template>
  <div class="report-client">
    <!-- Filters -->
    <!--    table-->

    <b-overlay
      id="overlay-bg-product-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <div class="py-1 px-2 d-flex justify-content-between align-items-center">
          <div>
            <label>Thời gian</label>
            <div class="d-flex align-items-center">
              <DatePicker
                v-model="filterDateRange"
                placeholder="Chọn ngày"
                format="DD/MM/YYYY"
                :formatter="momentFormat"
                language="vi"
                input-class="form-control"
                wrapper-class="w-100"
                type="week"
              />
            </div>
          </div>
        </div>
        <b-table
          ref="refProductListTable"
          class="report-overview position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          hover
          primary-key="id"
          show-empty
          small
          empty-text="Không có kết quả nào"
          bordered
          :items="reportListWeek"
          :fields="tableColumns"
          sticky-header
          @row-clicked="rowClicked"
        >
          <template #thead-top="data">
            <b-tr class="thead-top">
              <b-th class="text-center">
                <span>STT</span>
              </b-th>
              <b-th class="text-center">
                <span>Tên Nhân Viên</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Thời Gian Truy Cập (phút)</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Số Căn Xem Chi Tiết (căn)</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Nhập Khách Hàng (số khách)</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Số Lần Đăng Nhập Trên Máy Tính (số lần)</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Số Lần Đăng Nhập Trên Điện Thoại (số lần)</span>
              </b-th>
              <b-th
                class="text-center"
                colspan="7"
              >
                <span>Số Lần Tìm Kiếm (số lần)</span>
              </b-th>
            </b-tr>
          </template>
          <template #head="data">
            <tr>
              <b-th colspan="2">
                <span>Thứ tự</span>
              </b-th>
            </tr>
          </template>
          <template #cell(user_id)="data">
            <span class="d-block font-weight-bold text-primary">{{ data.item.user_id }}</span>
          </template>
          <template #cell(fullname)="data">
            <span class="d-block text-primary">{{ data.item.fullname }}</span>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard,
  BTable,
  VBTooltip,
  BOverlay,
  BTr,
  BTh,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import reportStoreModule from '@/views/report/reportStoreModule'
import { subject } from '@casl/ability'

import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useReportOverview from './useReportOverview'
import 'vue2-datepicker/locale/vi'

export default {
  components: {
    BCard,
    BTable,
    DatePicker,
    BOverlay,
    BTr,
    BTh,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      subject,
      momentFormat: {
      // [optional] Date to String
        stringify: date => {
          const startOfWeek = moment(date).startOf('isoWeek').toDate()
          const endOfWeek = moment(date).endOf('isoWeek').toDate()
          console.log('startOfWeek', startOfWeek)
          console.log('endOfWeek', endOfWeek)
          return `${moment(startOfWeek).format('DD/MM/YYYY')} - ${moment(endOfWeek).format('DD/MM/YYYY')}`
        },
        // [optional]  String to Date
        parse: value => {
          console.log('go thẻeeeeee 22222')
          return (value ? moment(value, 'LL').toDate() : null)
        },
        // [optional] getWeekNumber
        getWeek: date => {
          // a number
          console.log('goooo thẻeeee 1')
          return 10
        },
      },
    }
  },
  computed: {
    Vietnamese() {
      return Vietnamese
    },
  },
  watch: {
    // userClientList() {
    //   setTimeout(() => {
    //     this.$refs.refProductListTable.refresh()
    //   }, 200)
    // },
  },
  created() {
    // this.filter.product_type = 'townhouse'
    this.fetchReportOverview().finally(() => {
      this.loading = false
    })
    // waiting for watching done
    setTimeout(() => {
      this.created = true
    }, 500)
  },
  mounted() {
    window.__reportClient = this
  },
  methods: {
    gotoProductDetail({ id }) {
      const route = this.$router.resolve({
        name: 'report-client-detail',
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchProducts().finally(() => {
        this.loading = false
      })
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      const route = this.$router.resolve({
        name: 'user-detail',
        params: { id: row.user_id },
        query: { open: 'report' },
      })
      window.open(route.href, '_blank')
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    return {
      ...useReportOverview(),
    }
  },
}
</script>
<style lang="scss" scoped>
.report-client {
  height: calc((var(--vh, 1vh) * 100) - calc(1rem + 4.45rem) - 46.9px - 4rem);
  #overlay-bg-product-list {
    height: 100%;
    .card {
      height: 100%;
    }
  }
}
</style>
<style lang="scss">
@import "src/@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue2-datepicker/scss/index.scss";
.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.product-badge {
  position: absolute;
  right: 0;
  font-size: 0.7rem !important;

  &.hot {
    top: 0.25rem;
  }

  &.corner {
    bottom: 0.25rem;
  }

  &.hidden-phone {
    top: 0.25rem;
  }
}

.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: 0.25rem !important;
  }
}
@supports (position: sticky) {
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 12;
  }
}
.report-overview.b-table-sticky-header > .table.b-table > thead > tr {
  background-color: #f3f2f7;
}
.report-overview.b-table-sticky-header > .table.b-table > tbody > tr td.td-sunday {
  background-color: rgba(81, 188, 218, 0.15);
  color: #000;
}
.report-overview.b-table-sticky-header > .table.b-table > thead > tr > th  {
  position: sticky;
  top: 30.3px;
  z-index: 12;
}
.report-overview.b-table-sticky-header > .table.b-table > thead > tr:not(.thead-top) > th:nth-child(1) {
  border-right: none;
}
.report-overview.b-table-sticky-header > .table.b-table > thead > tr:not(.thead-top) > th:nth-child(2) {
  border-left: none;
  padding-left: 0;
  div {
    position: relative;
    left: -15px;
  }
}
.report-overview.b-table-sticky-header > .table.b-table > thead > tr.thead-top > th  {
  position: sticky;
  top: 0px;
  z-index: 12;
}
</style>
